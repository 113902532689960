import { createAction, handleActions } from 'redux-actions'

const setSetup = createAction('SCREENS/INSIGHTS/SET_SETUP')
const resetSetup = createAction('SCREENS/INSIGHTS/RESET_SETUP')

const defaultState = {
  models: [
    { label: 'Profit & loss', value: 'Project' },
    { label: 'Estimates', value: 'Estimate' },
    { label: 'Orders', value: 'Order' },
    { label: 'Timesheets', value: 'Timesheet' },
  ],
  model: 'Estimate',
  fields: {
    Project: {
      client_id: 'Client',
      client_contact_id: 'Contact',
      contracts_manager_id: 'Contracts manager',
      supervisor_id: 'Supervisor',
    },
    Estimate: {
      client_id: 'Client',
      project_id: 'Project',
      client_contact_id: 'Contact',
      decision_maker_id: 'Decision maker',
      user_id: 'Owner',
      status_key: 'Status',
    },
    Order: {},
    Timesheet: {
      subcontractor_id: 'Subcontractor',
      project_id: 'Project',
    },
  },
  field: undefined,
}

const reducer = handleActions(
  {
    [setSetup]: (state, action) => ({ ...state, ...action.payload }),
    [resetSetup]: () => defaultState,
  },
  defaultState,
)

export { setSetup, resetSetup, reducer }
